import { Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const AutomationDialogContent = ({ envName, configJSON, setConfigJSON }) => {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography component="span" variant="h5">
          Run automation test for {envName}?
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <TextField
          fullWidth
          id="outlined-multiline-flexible"
          label="Payload (JSON)"
          placeholder="{}"
          multiline
          maxRows={8}
          value={configJSON}
          onChange={(e) => {
            setConfigJSON(e.target.value);
          }}
        />
      </Stack>
    </>
  );
};

export default AutomationDialogContent;
