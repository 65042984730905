/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AutomationRuns from "./AutomationRuns";
import { getReport } from "../../api/automationRuns";
import utilColors from "../../styles/utilColors";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";

const styles = {
  reportIframe: {
    background: utilColors.white,
    width: "100%",
    height: "87.5vh",
  },
};

const Automations = () => {
  const [tabValue, setTabValue] = useState("automationRuns");
  const [selectedTab, setSelectedTab] = useState("automationRuns");
  const [tabIds, setTabIds] = useState([]);
  const [reports, setReports] = useState({});

  useEffect(() => {
    if (selectedTab === "automationRuns") {
      setTabValue(selectedTab);
    }
    if (tabIds.includes(selectedTab) && reports[selectedTab]) {
      setTabValue(selectedTab);
    }
  }, [selectedTab, reports, tabIds]);

  const handleIdClick = (id) => {
    id = id.toString();
    if (!tabIds.includes(id)) {
      getReport(id, setReports);
      setTabIds((prev) => [...prev, id]);
    }
    setSelectedTab(id);
  };

  const handleTabClose = (id) => {
    setSelectedTab("automationRuns");
    setTabIds((prev) => prev.filter((val) => val !== id));
  };
  const tabs = tabIds.map((tabId, i) => {
    return (
      <Tab
        key={i}
        label={`${tabId} Report`}
        icon={
          <IconButton
            sx={{ padding: "2px" }}
            onClick={() => {
              handleTabClose(tabId);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        iconPosition="end"
        value={`${tabId}`}
      />
    );
  });
  const tabContent = tabIds.map((tabId, i) => {
    return (
      <TabPanel value={`${tabId}`} key={i}>
        <iframe
          title="HTML Content"
          srcDoc={reports[tabId]}
          style={styles.reportIframe}
        />
      </TabPanel>
    );
  });
  return (
    <Box sx={tabbedDataGridPage.mainBox}>
      <Box sx={tabbedDataGridPage.tabContainer}>
        <TabContext value={tabValue}>
          <Box sx={tabbedDataGridPage.tabListBox}>
            <TabList
              onChange={(e, newValue) => setSelectedTab(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              sx={tabbedDataGridPage.tabList}
            >
              <Tab
                key="main"
                label="Automation Runs"
                value="automationRuns"
                icon={<></>}
                iconPosition="end"
              />
              {tabs}
            </TabList>
          </Box>

          <TabPanel key="main" value="automationRuns">
            <AutomationRuns handleIdClick={handleIdClick} />
          </TabPanel>
          {tabContent}
        </TabContext>
      </Box>
    </Box>
  );
};

export default Automations;
