import { Modal } from "@mui/material";
import { codeCoverageUrl } from "../../api/codeCoverage";
import styles from "./styles";

const ReportModal = ({ modal, setModal }) => {
  return (
    <Modal
      open={modal?.isOpen}
      onClose={() => setModal({ isOpen: false })}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <iframe
        src={`${codeCoverageUrl}/${modal?.id}/app-groups/${modal?.appGroup}/release-labels/${modal?.releaseLabel}/report`}
        style={styles.reportIframe}
      />
    </Modal>
  );
};
export default ReportModal;
