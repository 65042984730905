import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const tenantInstanceUrl = `${devopsUrl}/tenant-instance`;
export const automationUrl = `${devopsUrl}/automation`;
export const tenantInstancesUrl = `${devopsUrl}/tenant-instances`;
const APP_GROUP = "tessell";

export const useGetEnvs = () => {
  return useLazyFetch(tenantInstancesUrl, {
    headers: getHeaders(),
  });
};

export const useGetUsers = () => {
  return useLazyFetch(`${devopsUrl}/users`, {
    headers: getHeaders(),
  });
};

export const useGetTenantServices = () => {
  return useLazyFetch(
    `${devopsUrl}/applications/app-groups/${APP_GROUP}/release-manifests`,
    {
      headers: getHeaders(),
    }
  );
};

export const useRequestCP = () => {
  return useLazyPost(`${devopsUrl}/requests/control-plane`, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const useRequestBYOA = () => {
  return useLazyPost(`${devopsUrl}/requests/byoa-subscriptions`, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const patchEnv = (url, body) => {
  return fetch(url, {
    headers: getHeaders(),
    method: "PATCH",
    credentials: "include",
    body: JSON.stringify(body),
  });
};

export const useHibernateEnv = () => {
  return useLazyPost(`${tenantInstanceUrl}/hibernate`, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const useWakeUpEnv = () => {
  return useLazyPost(`${tenantInstanceUrl}/wakeup`, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const useDeleteEnv = () => {
  return useLazyPost(tenantInstanceUrl, {
    headers: getHeaders(),
    method: "DELETE",
  });
};

export const useConvertToVPTC = () => {
  return useLazyPost(`${tenantInstanceUrl}/to-vptc`, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const useAddLicences = () => {
  return useLazyPost(`${tenantInstanceUrl}/add-licences`, {
    headers: getHeaders(),
    method: "PATCH",
  });
};

export const useRunAutomationOnEnv = () => {
  return useLazyPost(automationUrl, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const useRefreshEnv = () => {
  return useLazyPost(`${tenantInstanceUrl}/refresh`, {
    headers: getHeaders(),
    method: "POST",
  });
};

export const useGetLabelsToRefresh = () => {
  return useLazyFetch(`${tenantInstanceUrl}/refresh/available-labels`, {
    headers: getHeaders(),
    method: "GET",
  });
};

export const useGetLabelsToCreate = () => {
  return useLazyFetch(`${tenantInstancesUrl}/available-labels`, {
    headers: getHeaders(),
    method: "GET",
  });
};

export const useGetByoaSubscriptions = () => {
  return useLazyFetch(`${tenantInstancesUrl}/byoa-subscriptions`, {
    headers: getHeaders(),
    method: "GET",
  });
};

export const useDeboardByoaSubscriptions = () => {
  return useLazyFetch(`${tenantInstancesUrl}/byoa-subscriptions/`, {
    headers: getHeaders(),
    method: "DELETE",
  });
};
