import { Divider, IconButton, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { Virtuoso } from "react-virtuoso";
import { FormControl, MenuItem, Select } from "@mui/material";
import { roles } from "../Environments";
import { useState } from "react";
import { ControlPointRounded } from "@mui/icons-material";

const MembersList = ({ env, addMember, modifyMemberRole, deleteMember }) => {
  const members = env?.indexedMembers
    ?.filter((email) => email in env?.members)
    ?.map((email) => {
      return {
        email: email,
        role: env?.members[email],
      };
    });

  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");

  const handleAddMember = (event) => {
    addMember(env?.id, email, role, event);
    clearAddMember();
  };

  const clearAddMember = () => {
    setEmail("");
    setRole("");
  };
  return (
    <Box sx={{ width: "100%", height: "100%", padding: "10px" }}>
      <Stack sx={{ height: 1 }}>
        <Stack
          direction="row"
          alignItems={"center"}
          // justifyContent={"space-between"}
        >
          <Typography variant="h5" mb={"5px"}>
            Members
          </Typography>
          <Typography variant="h5" mb={"5px"}>
            Roles
          </Typography>
        </Stack>
        <Divider />
        <Virtuoso
          style={{ minHeight: "auto", maxHeight: "90%" }}
          data={members}
          components={UserRole}
          itemContent={(index, member) => (
            <UserRole
              envId={env?.id}
              index={index}
              {...member}
              modifyMemberRole={modifyMemberRole}
              deleteMember={deleteMember}
            />
          )}
        />
        <Stack direction="row" spacing={2} alignItems="center" mt="3px">
          <Autocomplete
            noOptionsText="No Assignee available"
            label="Assignee"
            sx={{ width: "70%", flexGrow: 4 }}
            size="small"
            value={email || ""}
            fullWidth
            options={env?.addableMembers?.map((v) => v.email)}
            onChange={(event, newValue) => {
              event.preventDefault();
              event.stopPropagation();
              setEmail(newValue);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select Assignee"
                hiddenLabel
                size="small"
              />
            )}
          />

          <Select
            sx={{ width: "30%", flexGrow: 1 }}
            size="small"
            label="ROLE"
            value={role}
            onChange={(e) => setRole(e.target.value)}
          >
            {Object?.keys(roles)?.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          <IconButton
            sx={{ m: 0 }}
            flex="1"
            mr={0}
            onClick={(event) => handleAddMember(event)}
          >
            <ControlPointRounded />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

const UserRole = ({
  envId,
  index,
  email,
  role,
  modifyMemberRole,
  deleteMember,
}) => {
  const handleMemberRoleChange = (event) => {
    modifyMemberRole(envId, email, event.target.value);
  };
  const handleDeleteMember = (event) => {
    deleteMember(envId, email, event.target.value);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center" mt="3px">
      <TextField
        sx={{ width: "70%", flexGrow: 4 }}
        size="small"
        value={email}
        InputProps={{
          readOnly: true,
        }}
      />
      <FormControl sx={{ width: "30%", flexGrow: 1 }}>
        <Select
          id="demo-simple-select"
          value={role}
          size="small"
          onChange={(event) => handleMemberRoleChange(event)}
        >
          {Object?.keys(roles)?.map((role) => (
            <MenuItem key={role} value={role}>
              {role}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton flex="1" onClick={(event) => handleDeleteMember(event)}>
        <HighlightOffRoundedIcon />
      </IconButton>
    </Stack>
  );
};

export default MembersList;
