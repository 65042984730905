import pako from "pako";
import { useLazyFetch } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";
import { adaptAutomations } from "../adapters/getAutomationRuns";
import { useMemo } from "react";

export const automationsUrl = `${devopsUrl}/automation/automations`;

export const useGetAutomationRuns = () => {
  const res = useLazyFetch(automationsUrl, {
    headers: getHeaders(),
  });
  res.response = useMemo(() => {
    return res?.response?.length
      ? adaptAutomations(res.response)
      : res.response;
  }, [res.response]);
  return res;
};

export const getReport = async (id, setReports) => {
  try {
    const res = await fetch(`${automationsUrl}/${id}/report`, {
      credentials: "include",
      headers: getHeaders(),
    });
    if (res.status >= 300) {
      const resp = await res.text();
      setReports((prev) => ({
        ...prev,
        [id]: resp,
      }));
    } else {
      const arrayBuffer = await res.arrayBuffer();
      const html = pako.inflate(arrayBuffer, { to: "string" });
      setReports((prev) => ({
        ...prev,
        [id]: html,
      }));
    }
  } catch (error) {
    setReports((prev) => ({ ...prev, [id]: error }));
  }
};
