/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { tokens } from "../../theme";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import ReportModal from "./ReportModal";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import { codeCoverageItemAdapter } from "./utils";
import { useGetCodeCoverageData } from "../../api/codeCoverage";
import { useGetAppGroups, useGetReleases } from "../../api/releases";
import styles from "./styles";
import { Add, Remove } from "@mui/icons-material";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { APP_GROUPS } from "../../utils/constants";
import { codeCoverageUrl } from "../../api/codeCoverage";
import { downloadFileFromUrl } from "../../utils/helperFunctions";

const queryParamsForGetReleases = "?loadRepos=false&loadCommits=false";

const MAIN_BRANCH = "main";

const CodeCoverage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [appGroups, setAppGroups] = useState([]);
  const [selectedAppGroup, setSelectedAppGroup] = useState("");
  const [releases, setReleases] = useState([]);
  const [selectedRelease, setSelectedRelease] = useState(MAIN_BRANCH);
  const [codeCoverageData, setCodeCoverageData] = useState([]);
  const [expandedRow, setExpandedRow] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [reportModal, setReportModal] = useState({});

  const { postData: getAppGroups, response: getAppGroupsResp } =
    useGetAppGroups();

  const {
    postData: getCodeCoverageData,
    response: getCodeCoverageDataResp,
    isLoading: isLoadingCodeCoverageData,
  } = useGetCodeCoverageData(selectedAppGroup, selectedRelease);

  const { postData: getReleases, response: getReleasesResp } =
    useGetReleases(selectedAppGroup);

  useEffect(() => {
    getAppGroups();
  }, []);

  useEffect(() => {
    if (getAppGroupsResp?.response?.length) {
      setAppGroups(getAppGroupsResp.response);
      setSelectedAppGroup(
        getAppGroupsResp?.response?.includes(APP_GROUPS.TESSELL)
          ? APP_GROUPS.TESSELL
          : getAppGroupsResp.response[0]
      );
    }
  }, [getAppGroupsResp]);

  useEffect(() => {
    if (selectedAppGroup !== "") {
      getReleases(queryParamsForGetReleases);
    }
  }, [selectedAppGroup]);

  useEffect(() => {
    if (getReleasesResp?.response?.length) {
      setReleases(
        [MAIN_BRANCH].concat(
          getReleasesResp.response?.map(
            (releaseJson) => releaseJson?.release_label
          )
        )
      );
      setSelectedRelease(MAIN_BRANCH);
    }
  }, [getReleasesResp]);

  useEffect(() => {
    if (selectedAppGroup) {
      getCodeCoverageData();
    }
  }, [selectedAppGroup, selectedRelease]);

  useEffect(() => {
    if (getCodeCoverageDataResp?.response) {
      setCodeCoverageData(
        getCodeCoverageDataResp.response?.map(codeCoverageItemAdapter)
      );
    }
  }, [getCodeCoverageDataResp]);

  const closeHistory = () => {
    setExpandedRow("");
    setCodeCoverageData(
      codeCoverageData?.filter((item) => {
        return item?.parentId === undefined;
      })
    );
  };

  const openHistory = (row) => {
    setSortModel([]);
    setExpandedRow(row?.id);
    const data = codeCoverageData
      ?.filter((item) => {
        return item?.parentId === undefined;
      })
      ?.map((item) => {
        if (item?.id !== row?.id) {
          return item;
        }
        return [row].concat(
          row?.history
            ?.map((historyItem) => {
              historyItem = codeCoverageItemAdapter(historyItem);
              historyItem.parentId = item.id;
              return historyItem;
            })
            ?.filter((_, i) => i !== 0)
        );
      })
      ?.flat();
    setCodeCoverageData(data);
  };

  const getShowHistoryIcon = (row) => {
    if (row?.parentId) {
      return <></>;
    }
    return row?.id === expandedRow ? <Remove /> : <Add />;
  };

  const getCoverageCell = (data) => {
    return (
      <Tooltip
        title={
          <>
            <Typography>Covered: {data?.covered}</Typography>
            <Typography>Skipped: {data?.skipped}</Typography>
            <Typography>Total: {data?.total}</Typography>
          </>
        }
      >
        <Typography variant="h5">{(data?.percentage || "0") + "%"}</Typography>
      </Tooltip>
    );
  };

  const handleShowReport = (id) => {
    setReportModal({
      appGroup: selectedAppGroup,
      releaseLabel: selectedRelease,
      id: id,
      isOpen: true,
    });
  };

  const columns = [
    {
      field: "actions",
      type: "actions",
      flex: 0.1,
      getActions: ({ row }) => [
        <GridActionsCellItem
          label="Show History"
          disabled={row?.history?.length === 1}
          icon={getShowHistoryIcon(row)}
          onClick={() => {
            row.id === expandedRow ? closeHistory(row) : openHistory(row);
          }}
        />,
      ],
    },
    {
      field: "repoName",
      flex: 3,
      cellClassName: "name-column--cell",
      renderHeader: () => (
        <Typography paddingLeft="15px" variant="h5">
          Repository
        </Typography>
      ),
      renderCell: ({ row: { repoName } }) => (
        <Typography variant="h5" paddingLeft="15px">
          {repoName || ""}
        </Typography>
      ),
    },
    {
      field: "language",
      renderHeader: () => <Typography variant="h5">Language</Typography>,
      flex: 2,
      renderCell: ({ row: { language } }) => (
        <Typography variant="h5">{language || ""}</Typography>
      ),
    },
    {
      field: "report",
      renderHeader: () => <Typography variant="h5">Report</Typography>,
      flex: 1.5,
      renderCell: ({ row: { id, reportPath } }) => (
        <Stack direction="row">
          {reportPath?.endsWith(".html") ? (
            <DataGridButton
              sx={styles.reportButton}
              onClick={() => handleShowReport(id)}
            >
              <Typography sx={dataGridButtonTypography(colors)}>
                View
              </Typography>
            </DataGridButton>
          ) : (
            <DataGridButton
              sx={styles.reportButton}
              onClick={() =>
                downloadFileFromUrl(
                  `${codeCoverageUrl}/${id}/app-groups/${selectedAppGroup}/release-labels/${selectedRelease}/report`
                )
              }
            >
              <Typography sx={dataGridButtonTypography(colors)}>
                Download
              </Typography>
            </DataGridButton>
          )}
        </Stack>
      ),
    },
    {
      field: "tag",
      renderHeader: () => <Typography variant="h5">Git Tag</Typography>,
      flex: 2,
      renderCell: ({ row: { tag } }) => (
        <Typography variant="h5">{tag || ""}</Typography>
      ),
    },
    {
      field: "branchCoveragePercentage",
      renderHeader: () => <Typography variant="h5">Branch Coverage</Typography>,
      flex: 2,
      renderCell: ({ row: { branchCoverage } }) =>
        getCoverageCell(branchCoverage),
    },
    {
      field: "statementCoveragePercentage",
      renderHeader: () => (
        <Typography variant="h5">Statement Coverage</Typography>
      ),
      flex: 2,
      renderCell: ({ row: { statementCoverage } }) =>
        getCoverageCell(statementCoverage),
    },
  ];

  return (
    <Box sx={{ mx: "5px", mt: "5vh" }}>
      <ReportModal modal={reportModal} setModal={setReportModal} />
      <Grid sx={{ mt: "-50px" }} container columns={2} alignItems={"center"}>
        <Grid item xs={1}>
          <Header
            title="Code Coverage"
            subtitle={`Manage releases for: ${selectedAppGroup}`}
          />
        </Grid>
        <Grid item xs={1}>
          <Stack direction={"row-reverse"}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => {
                getCodeCoverageData();
              }}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            <FormControl sx={styles.viewSelectForm}>
              <InputLabel id="select-label">Release</InputLabel>
              <Select
                labelId="select-release-label"
                id="select-release"
                value={selectedRelease}
                sx={styles.viewSelectSelect}
                label="Release"
                onChange={(e) => setSelectedRelease(e.target.value)}
              >
                {releases.map((release, i) => (
                  <MenuItem value={release} key={i}>
                    {release}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={styles.viewSelectForm}>
              <InputLabel id="select-label">App Group</InputLabel>
              <Select
                labelId="select-app-group-label"
                id="select-app-group"
                value={selectedAppGroup || " "}
                sx={styles.viewSelectSelect}
                label="App Group"
                onChange={(e) => setSelectedAppGroup(e.target.value)}
              >
                {appGroups.map((appGroup, i) => (
                  <MenuItem value={appGroup} key={i}>
                    {appGroup}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      <Box sx={styles.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          onSortModelChange={(model) => {
            setSortModel(model);
            closeHistory();
          }}
          sortModel={sortModel}
          getRowId={(row) => row?.id}
          loading={isLoadingCodeCoverageData}
          rows={codeCoverageData}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default CodeCoverage;
