import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGetAutomationRuns } from "../../api/automationRuns";
import DataGridButton from "../../components/DataGridButton";
import * as React from "react";
import { green, red, yellow } from "@mui/material/colors";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import { Close, DoDisturb, Done } from "@mui/icons-material";
import { INFINITE_SCROLLING_PAGE_SIZE } from "../../utils/constants";

const styles = {
  resultStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
    margin: "auto",
  },
  resultIconsStyle: {
    Passed: { color: green[500] },
    Skipped: { color: yellow[800] },
    Failed: { color: red[500] },
  },
};

const AutomationRuns = ({ handleIdClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [automationRuns, setAutomationRuns] = useState([]);
  const [limit, setLimit] = useState(INFINITE_SCROLLING_PAGE_SIZE);

  const {
    postData: getAutomationRuns,
    response: getAutomationRunsResp,
    responseStatus: getAutomationRunsRespStatus,
    isLoading: isLoadingAutomationRuns,
  } = useGetAutomationRuns();

  const getAutomationRunsWrapper = () => {
    getAutomationRuns(`?limit=${limit}`);
  };

  useEffect(() => {
    getAutomationRunsWrapper();
  }, [limit]);

  useEffect(() => {
    if (getAutomationRunsResp?.length) {
      setAutomationRuns(getAutomationRunsResp);
    }
  }, [getAutomationRunsResp]);

  const columns = [
    {
      field: "run_id",
      flex: 1,
      renderHeader: () => (
        <Typography paddingLeft="15px" variant="h5">
          Run ID
        </Typography>
      ),
      renderCell: ({ row: { run_id } }) => (
        <Typography variant="h5" paddingLeft="15px">
          {run_id || ""}
        </Typography>
      ),
    },
    {
      field: "env_name",
      renderHeader: () => <Typography variant="h5">Environment</Typography>,
      flex: 1.5,
      renderCell: ({ row: { env_name } }) => (
        <Typography variant="h5">{env_name || ""}</Typography>
      ),
    },
    {
      renderHeader: () => <Typography variant="h5">Report</Typography>,
      headerAlign: "center",
      flex: 2,
      renderCell: ({ row: { run_id, status } }) => (
        <DataGridButton
          sx={dataGridButtonStyles.refreshButton}
          onClick={() => handleIdClick(run_id)}
          disabled={status !== "COMPLETED"}
        >
          <Typography sx={dataGridButtonTypography(colors)}>
            View Report
          </Typography>
        </DataGridButton>
      ),
    },
    {
      field: "status", // add the abort while running
      renderHeader: () => <Typography variant="h5">Status</Typography>,
      headerAlign: "center",
      flex: 1,
      renderCell: ({ row: { status } }) => (
        <Typography variant="h5">{status || ""}</Typography>
      ),
    },
    {
      field: "result",
      renderHeader: () => <Typography variant="h5">Result</Typography>,
      headerAlign: "center",
      flex: 3,
      renderCell: ({ row: { result } }) => (
        <Box sx={styles.resultStack}>
          <Typography width={"25px"} textAlign={"right"} variant="h5">
            {result.Passed}
          </Typography>
          <Done sx={styles.resultIconsStyle.Passed} />
          <Typography width={"25px"} textAlign={"right"} variant="h5">
            {result.Skipped}
          </Typography>
          <DoDisturb sx={styles.resultIconsStyle.Skipped} />
          <Typography width={"25px"} textAlign={"right"} variant="h5">
            {result.Failed}
          </Typography>
          <Close sx={styles.resultIconsStyle.Failed} />
        </Box>
      ),
    },
    {
      field: "start_time",
      renderHeader: () => <Typography variant="h5">Start Time</Typography>,
      flex: 2,
      renderCell: ({ row: { started_on } }) => (
        <Typography variant="h5">{started_on || ""}</Typography>
      ),
    },
    {
      field: "end_time",
      renderHeader: () => <Typography variant="h5">End Time</Typography>,
      flex: 2,
      renderCell: ({ row: { ended_on } }) => (
        <Typography variant="h5">{ended_on || ""}</Typography>
      ),
    },
    {
      field: "submitted_by",
      renderHeader: () => <Typography variant="h5">Created By</Typography>,
      flex: 3,
      renderCell: ({ row: { submitted_by } }) => (
        <Typography variant="h5">{submitted_by || ""}</Typography>
      ),
    },
  ];
  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid xs={1}>
          <Header
            title="Automation Runs"
            subtitle="View the automation runs you have access to"
          />
        </Grid>
        <Grid xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => getAutomationRunsWrapper()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={tabbedDataGridPage.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          onRowsScrollEnd={() => {
            setLimit((prev) =>
              prev <= automationRuns.length
                ? prev + INFINITE_SCROLLING_PAGE_SIZE
                : prev
            );
          }}
          scrollEndThreshold={1}
          loading={isLoadingAutomationRuns}
          rows={automationRuns}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default AutomationRuns;
