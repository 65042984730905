import moment from "moment";
import { timeFormat } from "../utils/constants";

export const adaptAutomations = (automationRuns) => {
  return automationRuns?.map((run) => {
    let newRunObj = structuredClone(run);
    newRunObj["id"] = run?.run_id;

    newRunObj.started_on = run?.start_time
      ? moment(run?.start_time)?.format(timeFormat)
      : "---";

    newRunObj.ended_on = run?.end_time
      ? moment(run?.end_time)?.format(timeFormat)
      : "---";

    newRunObj.result = {
      Passed: run?.result_summary?.passed || 0,
      Failed: run?.result_summary?.failed || 0,
      Skipped: run?.result_summary?.skipped || 0,
    };
    return newRunObj;
  });
};
